<template>
  <v-card>
    <v-card-title primary-title>
      <v-icon left large>mdi-cart-arrow-down</v-icon>
      <span class="headline">Pedidos</span>
      <v-spacer></v-spacer>

      <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

      <v-dialog v-model="dialogEliminar" persistent max-width="290">
        <v-card>
          <v-card-title class="title">¿Eliminar registro?</v-card-title>
          <v-card-text>{{ itemEliminar.nombre }}</v-card-text>
          <v-card-actions>
            <v-btn color="error" depressed @click="borrarItem">Si</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="info" depressed @click="dialogEliminar = false; itemEliminar = Object.assign({}, defaultItem)">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogEditarProducto" scrollable max-width="450px">
        <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="$v.defaultProducto.descripcion.$model"
                    @input="$v.defaultProducto.descripcion.$touch()"
                    @blur="$v.defaultProducto.descripcion.$touch()"
                    :error-messages="productoDescripcionErrors"
                    label="Nombre del producto"
                    hint="escribe completo el nombre del zapato"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="$v.defaultProducto.precio.$model"
                    @input="$v.defaultProducto.precio.$touch()"
                    @blur="$v.defaultProducto.precio.$touch()"
                    :error-messages="productoPrecioErrors"
                    label="Precio del producto (incluir + $10)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn class="error" dark small @click="showDialogProducto(false)" depressed>Cerrar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="info" dark small @click="agregarProducto" depressed>
                    <!-- <v-icon left>mdi-checkbox-marked-circle</v-icon> -->
                    <span>Agregar</span>
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="dialogEditar" scrollable max-width="1000px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on" depressed @click="nuevoItem">
              <v-icon left>mdi-plus</v-icon> Nuevo</v-btn>
        </template>
        
        <v-card>
            <v-card-title>
              <span class="title">{{ formTitle }}</span>
              <v-spacer></v-spacer>
              <span class="title">{{ fechahoraPedido }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col md="7">
                  <v-row>
                      <v-col cols="12">
                          <v-text-field
                          dense
                          v-model.trim="$v.editedItem.vendedor_nombre.$model"
                          @input="$v.editedItem.vendedor_nombre.$touch()"
                          @blur="$v.editedItem.vendedor_nombre.$touch()"
                          label="Vendedor*"
                          hint="escribe el nombre completo del vendedor"
                          required
                          :error-messages="vendedorErrors"
                          ></v-text-field>
                      </v-col>
                  </v-row>

                  <v-row>
                      <v-col cols="12" md="8">
                          <v-text-field 
                          dense
                          v-model.trim="$v.editedItem.cliente_nombre.$model"
                          @input="$v.editedItem.cliente_nombre.$touch()"
                          @blur="$v.editedItem.cliente_nombre.$touch()"
                          :error-messages="cliente_nombreErrors"
                          label="Cliente*"
                          hint="escribe aqui el nombre completo del cliente"
                          required
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                          <v-text-field
                          dense
                          v-model.trim="$v.editedItem.cliente_telefono.$model"
                          @input="$v.editedItem.cliente_telefono.$touch()"
                          @blur="$v.editedItem.cliente_telefono.$touch()"
                          :error-messages="cliente_telefonoErrors"
                          label="Celular*"
                          hint="10 digitos del celular"
                          required
                          ></v-text-field>
                      </v-col>
                  </v-row>
                          
                  <v-row>
                    <v-col cols="12" md="9">
                      <v-text-field
                        dense
                        v-model.trim="$v.editedItem.direccion_calle.$model"
                        @input="$v.editedItem.direccion_calle.$touch()"
                        @blur="$v.editedItem.direccion_calle.$touch()"
                        :error-messages="direccion_calleErrors"
                        label="Calle*"
                        hint="escribe solo la calle"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        dense
                        v-model.trim="$v.editedItem.direccion_numero.$model"
                        @input="$v.editedItem.direccion_numero.$touch()"
                        @blur="$v.editedItem.direccion_numero.$touch()"
                        :error-messages="direccion_numeroErrors"
                        label="Número*"
                        hint="número de casa"
                        required
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        dense
                        v-model.trim="$v.editedItem.direccion_entrecalles.$model"
                        @input="$v.editedItem.direccion_entrecalles.$touch()"
                        @blur="$v.editedItem.direccion_entrecalles.$touch()"
                        :error-messages="direccion_entrecallesErrors"
                        label="Entre calles"
                        hint="entre que calles es el domicilio"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-text-field
                        dense
                        v-model.trim="$v.editedItem.direccion_colonia.$model"
                        @input="$v.editedItem.direccion_colonia.$touch()"
                        @blur="$v.editedItem.direccion_colonia.$touch()"
                        :error-messages="direccion_coloniaErrors"
                        label="Colonia*"
                        hint="escribe aqui la colonia"
                        required
                        ></v-text-field>
                    </v-col>
                  </v-row>
                      
                  <v-row>
                      <v-col cols="12" md="7">
                        <v-spacer></v-spacer>
                        <v-switch v-model="editedItem.entrega_inmediata" :label="cuandoRepartir"></v-switch>
                      </v-col>
                      <v-col cols="12" md="5" v-if="!editedItem.entrega_inmediata">
                        

                        <v-menu
                          ref="dialog_fecha_entrega"
                          v-model="menu_fecha_entrega"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="editedItem.fecha_entrega"
                              label="Fecha de Entrega"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                          </template>
                          <v-date-picker
                          v-model="editedItem.fecha_entrega"
                          @input="menu_fecha_entrega = false"
                          ></v-date-picker>
                      </v-menu>
                          
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        name="txtobservaciones"
                        filled
                        label="Observaciones"
                        rows="1"
                        auto-grow
                        v-model="editedItem.observaciones"
                      ></v-textarea>
                  </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="5">
                
                      <v-data-table
                        :disable-pagination="true"
                        :disable-filtering="true"
                        :disable-sort="true"
                        :hide-default-footer="true"
                        :headers="prods_headers"
                        :items="editedItem.productos"
                        :options.sync="prods_options"
                        :items-per-page="5"
                        item-key="id"
                        no-data-text="Sin productos"
                        loading-text="cargando productos"
                      >
                        <template v-slot:top>
                          <v-toolbar flat color="white" dense>
                            
                              <v-badge
                                color="green"
                                :content="editedItem.productos.length"
                                :value="editedItem.productos.length"
                                overlap
                              >
                                <v-icon>mdi-cart-outline</v-icon>
                            </v-badge>
                            
                            <v-spacer></v-spacer>
                            <v-btn icon @click="showDialogProducto(true)">
                              <v-icon>mdi-cart-plus</v-icon>
                            </v-btn>
                          </v-toolbar>
                        </template>


                        <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              @click="borrarProducto(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                      </v-data-table>  
                    
                </v-col>
              </v-row>
            </v-card-text>
            
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn class="error" dark @click="cerrarDialog" depressed>Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="success" dark @click="grabar" depressed>
                    <v-icon left>mdi-checkbox-marked-circle</v-icon>
                    <span>Grabar</span>
                </v-btn>
            </v-card-actions>
        </v-card>

      </v-dialog>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col class="pb-0 pt-0 " cols="4" md="2">
              <v-text-field
                v-model="filtro_pedido"
                label="# Pedido"
                append-icon="mdi-magnify"
                clearable
                @click:clear="clearFiltroPedido"
                @keydown.enter="getDataFromApi"
            ></v-text-field>
        </v-col>
        <v-col class="pb-0 pt-0 " cols="8" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar cliente (escribe mas de 2 letras)"
            hide-details
            clearable
            @click:clear="clearFiltroCliente"
            @keydown.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
         <v-col class="pb-0 pt-0 " cols="12" md="4">
          <v-text-field
            v-model="filtro_vendedor"
            append-icon="mdi-magnify"
            label="Buscar vendedor (escribe mas de 2 letras)"
            hide-details
            clearable
            @click:clear="clearFiltroVendedor"
            @keydown.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="6" md="2" outline>
              <v-btn color="primary" depressed @click="getDataFromApi" icon><v-icon>mdi-filter</v-icon></v-btn>
              <v-btn color="primary" depressed @click="getDataFromApi" icon><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col> -->
      </v-row>
      
      <v-row>
        <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="grid_rows"
            :loading="isLoading"
            :options.sync="options"
            :search="search"
            :server-items-length="totalgrid_rows"
            :items-per-page="5"
            item-key="id"
            no-data-text="No se encontraron pedidos"
            loading-text="Buscando pedidos"
            :must-sort="true"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-options': [10,25,50]
            }"
          >
            <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editarItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="confirmaBorrar(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
          </v-data-table> 
        </v-col>
      </v-row>
    </v-card-text>

    <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
  </v-card>
</template>

<script>
import moment from 'moment'
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
import DialogEspera from '@/components/DialogEspera.vue'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'GridPedidos',
  mixins: [validationMixin],
  components: { SnackbarMensajes, DialogEspera },
  data: () => ({
    filtro_pedido: '',
    filtro_vendedor: '',
    sb: {
      mostrar: false,
      mensaje: '',
      color: 'success',
      timeout: 3000,
      multiline: false,
    },
    menu_fecha_entrega: false,
    dialogEspera: false,
    dialogEditar: false,
    dialogEliminar: false,
    dialogEditarProducto: false,
    isLoading: true,
    grid_rows: [],
    totalgrid_rows: 0,
    editando: false,
    search: '',
    itemEliminar: {},
    editedItem: {
    },
    defaultItem: {
      id: -1,
      fecha: '',
      entrega_inmediata: true,
      fecha_entrega: '',
      cliente_nombre: '',
      cliente_apellidos: '',
      cliente_telefono: '',
      direccion_calle: '',
      direccion_numero: '',
      direccion_colonia: '',
      direccion_referencia: '',
      direccion_entrecalles: '',
      idvendedor: null,
      vendedor: {},
      vendedor_nombre: '',
      horaentrega_desde: '',
      horaentrega_hasta: '',
      comision_domicilio: '',
      total_pedido: '',
      observaciones: '',
      productos: [],
      productos_borrados: []
    },
    defaultProducto: {
      id: -1,
      idpedido: -1,
      descripcion: '',
      precio: 0,
      sucursal: '',
      entregado: false,
    },
    options: {
      sortBy: ['id'],
      sortDesc: [true]
    },
    headers: [
        { text: 'Id', value: 'id', sortable: true },
        { text: 'F. Captura', value: 'fecha', sortable: true},
        { text: 'Vendedor', value: 'vendedor_nombre', sortable: true},
        { text: 'Cliente', value: 'cliente_nombre', align: 'start', sortable: true },
        { text: 'Celular', value: 'cliente_telefono', sortable: false },
        { text: 'Solicitado para', value: 'fecha_entrega', sortable: false },
        { text: 'Opciones', value: 'actions', sortable: false },
    ],
    prods_headers: [
      { text: 'Producto', value: 'descripcion', sortable: false},
      { text: 'Precio', value: 'precio', sortable: false},
      { text: 'Opciones', value: 'actions', sortable: false },
    ],
    prods_options: {},
  }),
  computed: {
    cuandoRepartir(){
      return this.editedItem.entrega_inmediata ? 'Siguiente Entrega Inmediata' : 'Entrega Otro Día'
    },
    formTitle () {
      return this.editando ? `Editar pedido #${this.editedItem.id}` : 'Nuevo pedido'
    },
    fechahoraPedido () {
      return this.editando ? `${this.editedItem.created_at}` : ''
    },
    vendedorErrors () {
      const errors = []
      if (!this.$v.editedItem.vendedor_nombre.$dirty) return errors
      !this.$v.editedItem.vendedor_nombre.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.vendedor_nombre.required && errors.push('Es obligatorio el nombre de vendedor.')
      return errors
    },
    cliente_nombreErrors () {
      const errors = []
      if (!this.$v.editedItem.cliente_nombre.$dirty) return errors
      !this.$v.editedItem.cliente_nombre.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.cliente_nombre.required && errors.push('Obligatorio.')
      return errors
    },
    cliente_telefonoErrors () {
      const errors = []
      if (!this.$v.editedItem.cliente_telefono.$dirty) return errors
      !this.$v.editedItem.cliente_telefono.minLength && errors.push('Captura los 10 digitos del celular.')
      !this.$v.editedItem.cliente_telefono.maxLength && errors.push('Se permiten máximo 10 dígitos.')
      !this.$v.editedItem.cliente_telefono.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_calleErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_calle.$dirty) return errors
      !this.$v.editedItem.direccion_calle.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.direccion_calle.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_numeroErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_numero.$dirty) return errors
      !this.$v.editedItem.direccion_numero.maxLength && errors.push('Se permiten máximo 50 caracteres')
      !this.$v.editedItem.direccion_numero.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_coloniaErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_colonia.$dirty) return errors
      !this.$v.editedItem.direccion_colonia.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.direccion_colonia.required && errors.push('Obligatorio.')
      return errors
    },
    productoPrecioErrors () {
      const errors = []
      if (!this.$v.defaultProducto.precio.$dirty) return errors
      !this.$v.defaultProducto.precio.numeric && errors.push('Precio inválido.')
      !this.$v.defaultProducto.precio.minValue && errors.push('Precio debe ser mayor a $10.')
      !this.$v.defaultProducto.precio.required && errors.push('Obligatorio.')
      return errors
    },
    productoDescripcionErrors () {
      const errors = []
      if (!this.$v.defaultProducto.descripcion.$dirty) return errors
      !this.$v.defaultProducto.descripcion.minLength && errors.push('Escribe la descripción completa incluyendo talla.')
      !this.$v.defaultProducto.descripcion.maxLength && errors.push('Se permiten máximo 250 caracteres.')
      !this.$v.defaultProducto.descripcion.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_entrecallesErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_entrecalles.$dirty) return errors
      !this.$v.editedItem.direccion_entrecalles.maxLength && errors.push('Se permiten máximo 250 caracteres.')
      return errors
    },

  },
  validations: {
    editedItem: {
      vendedor_nombre: { required, maxLength: maxLength(250) },
      cliente_nombre: { required, maxLength: maxLength(250) },
      cliente_telefono: { required, minLength: minLength(10), maxLength: maxLength(14) },
      direccion_calle: { required, maxLength: maxLength(250) },
      direccion_numero: { required, maxLength: maxLength(50) },
      direccion_colonia: { required, maxLength: maxLength(250) },
      direccion_entrecalles: { maxLength: maxLength(250) },
      productos: { required, minLength: minLength(1) },
    },
    defaultProducto: {
      descripcion: { required, minLength: minLength(7), maxLength: maxLength(250) },
      precio: { required, numeric, minValue: minValue(10) },
    },
  },
  watch: {
    options: {
      handler(){
                        this.getDataFromApi();
      }
    },
    // search: {
    //   handler() {
    //     if(this.search.length > 2 || this.search.length == 0){
    //       this.getDataFromApi();
    //     }
    //   }
    // }
  },
  methods: {
    clearFiltroPedido(event){
        this.filtro_pedido = "";
        this.getDataFromApi();
    },
    clearFiltroVendedor(event){
        this.filtro_vendedor = "";
        this.getDataFromApi();
    },
    clearFiltroCliente(event){
        this.search = "";
        this.getDataFromApi();
    },

    nuevoItem(){
      this.editando = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.fecha =  moment().format('YYYY-MM-DD');
      this.editedItem.fecha_entrega =  moment().format('YYYY-MM-DD');
      this.editedItem.vendedor_nombre = this.$store.getters.username;
      this.editedItem.productos = []
      this.editedItem.productos_borrados = []
      this.dialogEditar = true;
    },
    editarItem (item) {
      this.editando = true;
      this.editedItem.productos = []
      this.editedItem = Object.assign({}, item);
      this.editedItem.productos_borrados = []
      this.dialogEditar = true;
    },
    confirmaBorrar (item) {
      this.editando = false;
      this.itemEliminar = Object.assign({}, item);
      this.dialogEliminar = true;
    },
    borrarItem(){
      this.dialogEspera = true;
      ApiService.borrarPedido(this.$store.getters.token,this.itemEliminar.id)
          .then(response => {
              // 204 => No content
              
              if(response.status == 204){
                this.getDataFromApi();
                
                this.showMensaje(`Pedido eliminado`,'info',false)
                this.dialogEliminar = false;
                this.itemEliminar = Object.assign({}, this.defaultItem);
              }else{
                  this.showMensaje(`Error al eliminar pedido ${response.status} - ${response.statusText} `,'error',true)
              }
          })
          .catch(error => {
            this.showMensaje(`Error al borrar pedido ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(()=> {
            this.dialogEspera = false;
          })
    },

    cerrarDialog () {
      this.dialogEditar = false
      this.getDataFromApi()
      
      this.editando = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },
    showDialogProducto(mostrar){
      this.defaultProducto.id = -1;
      this.defaultProducto.idpedido = -1;
      this.defaultProducto.descripcion = ''
      this.defaultProducto.precio = ''
      this.dialogEditarProducto = mostrar
    },
    agregarProducto(){
      this.$v.defaultProducto.$touch()
      if(!this.$v.defaultProducto.$invalid){
        this.editedItem.productos.push(Object.assign({}, this.defaultProducto))
        this.dialogEditarProducto = false
      }
    },
    borrarProducto(item){
      if(item.id > -1) this.editedItem.productos_borrados.push(item)
      let idx = this.editedItem.productos.indexOf(item)
      this.editedItem.productos.splice(idx, 1)
    },
    grabarProductos: function(idpedido) {
      const data = {
        idpedido: idpedido,
        productos: this.editedItem.productos,
        borrados: this.editedItem.productos_borrados
      }

      ApiService.grabaProductosPedido(this.$store.getters.token,data)
          .then(response => {
              // 200 => OK Updated
              if(response.status == 200){
                this.getDataFromApi();
                this.showMensaje(`Pedido grabado ID: ${idpedido}`,'success',false)
                this.editando = false;
                this.dialogEditar = false;
                this.editedItem = Object.assign({}, this.defaultItem)
              }else{
                  this.showMensaje(`Error al grabar productos ${response.status} - ${response.statusText}`,'error',true)
              }
          })
          .catch(error => {
            this.showMensaje(`Error al grabar productos ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(() => {
            this.dialogEspera = false
          })
    },
    grabar: function(){
      if(this.editedItem.entrega_inmediata) this.editedItem.fecha_entrega = this.editedItem.fecha;

      this.$v.editedItem.$touch()
      if(this.$v.editedItem.productos.$invalid){
        this.showMensaje(`Debes incluir al menos un producto`,'error',true)
      }
      if(this.$v.editedItem.$invalid) return false;

      const data = {
          fecha: this.editedItem.fecha,
          cliente_nombre: this.editedItem.cliente_nombre,
          cliente_telefono: this.editedItem.cliente_telefono,
          vendedor_nombre: this.editedItem.vendedor_nombre,
          entrega_inmediata: this.editedItem.entrega_inmediata,
          fecha_entrega: this.editedItem.fecha_entrega,
          horaentrega_desde: this.editedItem.horaentrega_desde,
          direccion_calle: this.editedItem.direccion_calle,
          direccion_numero: this.editedItem.direccion_numero,
          direccion_colonia: this.editedItem.direccion_colonia,
          direccion_entrecalles: this.editedItem.direccion_entrecalles,
          observaciones: this.editedItem.observaciones
      }

      this.dialogEspera = true;
      ApiService.grabaPedido(this.$store.getters.token,data,this.editedItem.id)
          .then(response => {
              // 201 => Created :: 200 => OK Updated
              //const checkStatus = this.editedItem.id == -1 ? 201 : 200;
              if(response.status == 200 || response.status == 201){
                this.grabarProductos(response.data.id);
              }else{
                  this.showMensaje(`Error al grabar ${response.status} - ${response.statusText}`,'error',true)
              }
          })
          .catch(error => {
            this.dialogEspera = false
            this.showMensaje(`Error al grabar ${error.response.status} - ${error.response.statusText}`,'error',true)
          })
          .finally(() => {
            // this.dialogEspera = false
          })
    },
    getDataFromApi() {
      this.isLoading = true
      if(!this.filtro_pedido) this.filtro_pedido = '';
      if(!this.search) this.search = '';
      if(!this.filtro_vendedor) this.filtro_vendedor = '';
      let filtros = {
        idpedido: this.filtro_pedido,
        cliente_nombre: this.search,
        vendedor_nombre: this.filtro_vendedor,
      }
      
      ApiService.getPedidos(this.$store.getters.token,this.options,filtros)
        .then(response => {
          if(response.status == 200){
            this.grid_rows = response.data;
            this.totalgrid_rows = parseInt(response.headers['x-pagination-total-count']);
            this.isLoading = false;
          }else{
            this.showMensaje(`Error al obtener listado ${response.status} - ${response.statusText} `,'error',true)
          }
        })
        .catch(error => {
          this.showMensaje(`Error al obtener listado ${error.response.status} - ${error.response.statusText}`,'error',true)
          this.isLoading = false
        })
        .finally(() => (this.isLoading = false))
    }
  },
  mounted() {
    
    //this.getDataFromApi()
  },
  created() {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
}
</script>
